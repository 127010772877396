/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { Component } from "react";
import "./forgot.scss";
import logo from "../../../Assets/Images/brand_logo.png";
import { Card} from "@material-ui/core";
import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";
import httpRequest from "../../../Utils/httpRequest";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  updateToasterConfig,
} from "./../../../../src/redux/modules/app/actions";
import { getCookie } from "../../../Utils/cookies";

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      username: "",

      error: "",
      token: "",
      toasterConfig: {
        message: "",
        color: "",
        show: false,
      },
      message: "",
    };


    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.dismissError = this.dismissError.bind(this);
  }

  dismissError() {
    this.setState({ error: "" });
  }

  errorCallback = () => {
    this.props.history.push("/login");
  };
  detectKeyPress = (evt) => {
    if (evt.charCode === 13) {
      this.handleSubmit();
    }
  };
  removeReadOnly = () => {
    document.getElementById("username").removeAttribute("readOnly");

  };
  handleSubmit = () => {

    if (!this.state.username) {
      return this.setState({ error: "Username is required" });
    }

    httpRequest.postPublic(
      "forgetPassword",
      {
       
       "realmName":(window.location.host === "localhost:3000" ||
        window.location.host === "dev-superadmin.parkzeus.in")?"Rlm_ROBOIN_Parking_Admin_DEV_IN":"Rlm_ROBOIN_Parking_Admin_IN",
        "forgotPasswordRequest": {
         "realmname":(window.location.host === "localhost:3000" ||
         window.location.host === "dev-superadmin.parkzeus.in")?"Rlm_ROBOIN_Parking_Admin_DEV_IN":"Rlm_ROBOIN_Parking_Admin_IN",
         "emailId": this.state.username
      }}
      ,
      {},
      this.forgotSuccess,
      this.errorCallback,

    )
  }
  forgotSuccess = jsonResult => {
    // console.log("**********************", jsonResult.data);

    this.setState({
      message: jsonResult.data,

    });
    this.props.updateToasterConfig({
      show: true,
      message: 'The link has been sent',
      color: '#02C194',
    });
    this.props.history.push('/login');
  }
  errorCallback = error => {
    this.props.updateToasterConfig({
      show: true,
      message: error?.message,
      color: "#FF0000",
    });
  };


  handleUserChange(evt) {
    this.setState({
      username: evt.target.value,
    });
  }

  render() {
    return (
      <div className="ForgotPassword">
        <div className="ForgotPassword__wrapper">
          <Card>
            <div className="ForgotPassword__wrapper_box">
              <div className="ForgotPassword__wrapper_box_header">
                <div className="ForgotPassword__wrapper_box_header_logo">
                  <img src={logo} alt="header-logo" />
                </div>
                <div className="ForgotPassword__wrapper_box_header_text">
                  <span>Forgot Password</span>
                </div>
              </div>
              <Grid item>
                <span align="center">
                  Please enter your email Id. <br />
              We will send the link.
              </span>

              </Grid>
              {this.state.error && (
                <h3
                  data-test="error"
                  className="error"
                  onClick={this.dismissError}
                >
                  <button onClick={this.dismissError}>✖</button>
                  {this.state.error}
                </h3>
              )}
              <label>Email Id *</label>
              <input
                placeholder="enter email id"
                type="text"
                data-test="username"
                value={this.state.username}
                onChange={this.handleUserChange}
                id="username"
                autoComplete="off"
                readOnly
                onFocus={this.removeReadOnly}
              />

              <Grid container direction="row" spacing={1}>
                <Grid item xs>
                  <Button
                    variant="contained"
                    color="default"
                    type="button"
                    onClick={this.props.history.goBack}
                    style={{borderRadius:"25px",fontSize:"0.65rem"}}
                  >
                    Cancel
            </Button>
                </Grid>
                <Grid item xs>
                  <Button
                    style={{ 'float': 'right',borderRadius:"25px",fontSize:"0.65rem" }}
                    variant="contained"
                    color="primary"
                    type="button"
                    disabled={!(this.state.username)}
                    onClick={() => {
                      this.handleSubmit();
                    }}

                  >
                    Submit
            </Button>
                </Grid>
              </Grid>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    null,
    { updateToasterConfig }
  )(ForgotPassword)
);
