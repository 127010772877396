/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import * as axios from 'axios';
import TokenStorage from './tokenstorage';
import { getRefreshToken } from './refreshToken';
import { getCookie } from './cookies';

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
  async function (request) {

    if (request.url.includes("/forgetPassword") ||
    request.url.includes("/sendEmailOtp") ||
    request.url.includes("/config") ||
    request.url.includes("/otpChangePassword")) {

      return request
    }
    if (!request.url.includes('login')) {
      if (
        new Date(TokenStorage.getTokenExpirationTime()) <= new Date()
      ) {
        await getRefreshToken();
        request.headers['Authorization'] =
          'bearer ' + TokenStorage.getToken();
        return request;
      } else {
        return request;
      }
    } else {
      return request;
    }
  },
  function (error) {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    


    return Promise.reject(error);
  },
);

export default axiosInstance;
